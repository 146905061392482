import styled from "styled-components"
import { alignItems, boxShadow } from "styled-system"
import { themeGet } from "@styled-system/theme-get"

import { buttonStyle, colorStyle, sizeStyle } from "../customVariant"
import { base } from "../base"

interface ButtonStyleProps {
  buttonType?: string
  [key: string]: any
}

const ButtonStyle = styled.button<ButtonStyleProps>`
  /* button default style */
  cursor: pointer;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${themeGet("colors.primaryButtonText")};
  background: linear-gradient(
    -139deg,
    ${({ buttonType }) =>
      buttonType === "secondary"
        ? `rgba(255, 194, 17,0.2) 0%,rgba(255, 194, 17,0) 100%`
        : `#FFC211 0%, #e49400 100%`}
  );

  border: 1px solid;
  border-color: ${({ buttonType }) =>
    buttonType === "secondary" ? `rgba(255, 194, 17,0.5)` : `transparent`};

  border-radius: 100px;

  font-size: 18px;
  font-weight: 900;
  padding: 12px 28px;
  letter-spacing: 0.02em;
  min-width: 160px;
  min-height: 54px;
  text-decoration: none;
  text-transform: capitalize;
  transition: all 0.3s ease;
  background-position: 0% 50%;
  // margin-bottom: 20px;

  @media (max-width: 640px) {
    min-width: 200px;
    // margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    min-height: 40px;
    padding: 12px 20px;
    font-size: 16px;
  }

  span.btn-text {
    font-weight: 900;
    transition: all 0.1s;
    color: ${({ buttonType }) =>
      buttonType === "secondary" ? `#E4AA00` : `#001F58`};
  }
  span.btn-icon {
    display: flex;
    > div {
      display: flex !important;
    }
  }

  box-shadow: 0px 8px 20px rgba(245, 188, 33, 0.4);

  /* &:not(:disabled):focus, */
  &:not(:disabled):hover {
    outline: none;
    box-shadow:
      0px 16px 40px rgba(245, 188, 33, 0.4),
      inset 2px 2px 10px rgba(255, 255, 255, 0.3);
    background-position: 100% 50%;
    transform: scale(1.05);
    @media (max-width: 768px) {
      transform: scale(1);
    }
  }

  &:disabled,
  &[disabled] {
    background: #cccccc;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    box-shadow: 0px 4px 12px rgba(245, 188, 33, 0.4);
    &:focus,
    &:hover {
      box-shadow:
        0px 4px 16px rgba(245, 188, 33, 0.4),
        inset 1px 1px 5px rgba(255, 255, 255, 0.3);
    }
  }

  /* Secondary Button * /

  // &.secondary {
  //   background: none;
  //   border: 1px solid ${themeGet("colors.primary")};
  //   color: ${themeGet("colors.white")};
  // }

  /* Material style goes here */
  // &.is-material {
  //   box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
  //     0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  // }

  /* When button on loading stage */
  // &.is-loading {
  //   .btn-text {
  //     padding-left: ${themeGet("space.2", "8")}px;
  //     padding-right: ${themeGet("space.2", "8")}px;
  //   }
  // }

  /* Style system support */
  ${alignItems}
  ${boxShadow}
  ${buttonStyle}
  ${colorStyle}
  ${sizeStyle}
  ${base}
`

ButtonStyle.displayName = "ButtonStyle"

export default ButtonStyle
