import styled from "styled-components"

const ContainerWrapper = styled.div<{ fullWidth?: boolean }>`
  margin: 0 auto;
  max-width: ${props => (props.fullWidth ? "none" : "1600px")};
  width: 100%;
  padding: ${props => (props.fullWidth ? "0 0px" : "0 60px")};
  @media (max-width: 576px) {
    padding: ${props => (props.fullWidth ? "0 0px" : "0 20px")};
  }
`

export default ContainerWrapper
