import React from "react"
import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const BoxWrapper = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

const Line = styled.div<{ themeNew?: any }>`
  @keyframes animationLine {
    0% {
      opacity: 0;
    }

    75% {
      opacity: 100%;
    }
    100% {
      opacity: 0;
    }
  }
  width: 1px;
  height: 200px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  @media (max-width: 768px) {
    height: 120px;
  }

  &:before {
    content: "";
    display: block;
    width: 1px;
    height: 100%;
    background: linear-gradient(
      180deg,
      ${({ themeNew }) =>
          themeNew ? themeNew.primary : themeGet("colors.primary")}
        0%,
      ${({ themeNew }) =>
          themeNew ? themeNew.secondary : themeGet("colors.primaryLight")}
        80%,
      transparent 100%
    );
    animation: animationLine 3s ease infinite;
  }

  @keyframes animationDot {
    0% {
      top: 0;
      height: 30px;
    }
    75%,
    100% {
      top: 100%;
      opacity: 0;
      height: 0;
    }
  }
  &:after {
    content: "";
    display: block;
    width: 1px;
    height: 20px;
    border-radius: 2px;
    // background-color: ${themeGet("colors.primary")};
    background: linear-gradient(
      180deg,
      ${({ themeNew }) =>
          themeNew ? themeNew.primary : themeGet("colors.primary")}
        0%,
      ${({ themeNew }) =>
          themeNew ? themeNew.primary : themeGet("colors.primaryLight")}
        80%,
      transparent 100%
    );
    box-shadow: 0px 0px 30px #fff;
    position: absolute;
    top: 0;
    left: 0px;
    animation: animationDot 3s ease infinite;
  }
`

interface SectionLineProps {
  themeNew?: any
  [x: string]: any
}

const SectionLine = ({ themeNew, ...props }: SectionLineProps) => (
  <BoxWrapper {...props}>
    <Line themeNew={themeNew}></Line>
  </BoxWrapper>
)

export default SectionLine
