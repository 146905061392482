import React, { useEffect } from "react"
import { useRouter } from "next/router"

import Sticky from "react-stickynode"
import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"
import { useTranslation } from "next-i18next"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import ResetCSS from "@/common/assets/css/style"
import NavbarNew from "@/containers/common/NavbarNew"
import Footer from "@/containers/common/FooterNew"
import SectionLine from "@/common/components/SectionLine"
import MetaData from "@/common/components/MetaData"
import Link from "next/link"
import Button from "@/common/components/Button"
import Container from "@/common/components/UI/Container"
import { AppWrapper, ContentWrapper } from "@/containers/common/style"

const Custom404 = () => {
  const router = useRouter()
  const { locale } = useRouter()
  const { t } = useTranslation("common")

  useEffect(() => {
    if (router.asPath?.includes("/events")) {
      router.push("/events")
    }
    if (router.asPath?.includes("/blog")) {
      router.push("/blog")
    }
  }, [router])

  return (
    <React.Fragment>
      <div>
        <MetaData
          title={t("error.desc")}
          description={t("error.desc")}
          image="https://storage.googleapis.com/kryptogo-official-website-public/og-logo.webp"
          url={`https://www.kryptogo.com${
            locale === "en" ? "" : `/${locale}`
          }/404`}
          languageAlternates={[
            [
              {
                hrefLang: "en-US",
                href: "https://www.kryptogo.com/404"
              },
              {
                hrefLang: "zh-TW",
                href: "https://www.kryptogo.com/tw/404"
              },
              {
                hrefLang: "zh-CN",
                href: "https://www.kryptogo.com/cn/404"
              }
            ]
          ]}
        />
        <ResetCSS />
        {/* end of global and reset style */}
        {/* start app classic landing */}
        <AppWrapper>
          <Sticky top={0} innerZ={1000} activeClass="sticky-active">
            <NavbarNew />
          </Sticky>
          <Container>
            <ContentWrapper>
              <Section404 className="E404Section">
                <div>
                  <h1 dataspotlight="404">404</h1>
                  <div>
                    <h2>{t("error.desc")}</h2>
                    <Link href={"/"} passHref legacyBehavior>
                      <Button title={t("error.btn")} />
                    </Link>
                  </div>
                </div>
              </Section404>
            </ContentWrapper>
            <SectionLine />
          </Container>
          <Footer />
        </AppWrapper>
      </div>
    </React.Fragment>
  )
}

export const getStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, [
      "privacy",
      "common",
      "contactUs"
    ]))
  }
})

export default Custom404

const Section404 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  h1 {
    font-size: 20rem;
    position: relative;
    color: ${themeGet("colors.secondaryLight")};
    text-align: center;
    margin-top: 0px;

    &::before {
      content: "404";
      font-size: 20rem;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
      clip-path: ellipse(200px 200px at 0% 50%);
      animation: spotlight 4s infinite ease-in-out;
      color: transparent;
      background-color: #fbab7e;
      background-image: linear-gradient(
        62deg,
        #ffea05 10%,
        #f7ce68 40%,
        ${themeGet("colors.primary")} 100%
      );
      -webkit-background-clip: text;
      text-shadow: 1px 1px 30px rgba(238, 168, 17, 0.773);
    }

    &::after {
      content: "404";
      font-size: 20rem;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
      clip-path: ellipse(200px 200px at 0% 50%);
      animation: spotlight 4s infinite ease-in-out;
      color: transparent;
      background-color: #fbab7e;
      background-image: linear-gradient(
        62deg,
        #ffea05 10%,
        #f7ce68 40%,
        ${themeGet("colors.primary")} 100%
      );
      -webkit-background-clip: text;
    }
  }

  div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;

    h2 {
      display: inline-block;
      margin-right: 50px;
    }

    h2 {
      margin: 0px !important;
    }

    button {
      margin: 0px;
    }
  }

  @media (max-width: 800px) {
    h1 {
      font-size: 10rem;

      &::before {
        content: "404";
        font-size: 10rem;
        clip-path: ellipse(200px 200px at 0% 50%);
        text-shadow: 1px 1px 20px rgba(238, 168, 17, 0.773);
      }

      &::after {
        content: "404";
        font-size: 10rem;
        clip-path: ellipse(200px 200px at 0% 50%);
      }
    }

    div:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 24px;
      flex-direction: column;

      h2 {
        display: inline-block;
        margin-right: 50px;
        margin-top: 50px;
      }

      button {
        margin: 0px;
        margin-top: 10px;
      }
    }
  }

  @keyframes spotlight {
    0% {
      clip-path: ellipse(200px 200px at -40% 50%);
    }

    50% {
      clip-path: ellipse(300px 300px at 160% 50%);
    }

    100% {
      clip-path: ellipse(200px 200px at -40% 50%);
    }
  }
`
