import React, { Fragment } from "react"
import ButtonStyle from "./button.style"
import Loader from "../Loader"

interface ButtonProps {
  type?: "button" | "secondary" | "submit" | "reset"
  title?: string
  icon?: any
  disabled?: boolean
  onClick?: Function
  loader?: any
  loaderColor?: string
  isMaterial?: boolean
  isLoading?: boolean
  className?: string
  href?: string
  colors?:
    | "primary"
    | "secondary"
    | "warning"
    | "error"
    | "primaryWithBg"
    | "secondaryWithBg"
    | "warningWithBg"
    | "errorWithBg"
  variant?: "textButton" | "outlined" | "fab" | "extendedFab"
  iconPosition?: "left" | "right"
  [key: string]: any
}

const Button = ({
  type = "button",
  title,
  icon,
  disabled = false,
  iconPosition,
  onClick,
  loader,
  loaderColor,
  isMaterial = false,
  isLoading = false,
  className,
  href,
  ...props
}: ButtonProps) => {
  // Add all classs to an array
  const addAllClasses = ["reusecore__button"]

  // isLoading prop checking
  if (isLoading) {
    addAllClasses.push("is-loading")
  }

  // isMaterial prop checking
  if (isMaterial) {
    addAllClasses.push("is-material")
  }

  // className prop checking
  if (className) {
    addAllClasses.push(className)
  }

  // Checking button loading state
  const buttonIcon =
    isLoading !== false ? (
      <Fragment>
        {loader ? loader : <Loader loaderColor={loaderColor || "#30C56D"} />}
      </Fragment>
    ) : (
      icon && <span className="btn-icon">{icon}</span>
    )

  // set icon position
  const position = iconPosition || "right"

  return (
    <a href={href}>
      <ButtonStyle
        buttonType={type}
        className={addAllClasses.join(" ")}
        icon={icon}
        disabled={disabled || isLoading}
        icon-position={position}
        onClick={onClick}
        {...props}
      >
        {position === "left" && buttonIcon}
        {title && <span className="btn-text">{title}</span>}
        {position === "right" && buttonIcon}
      </ButtonStyle>
    </a>
  )
}

export default Button
