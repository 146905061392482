import React from "react"
import ContainerWrapper from "./style"

interface ContainerProps {
  children: any
  className?: string
  fullWidth?: boolean
}

const Container = ({ children, className, fullWidth }: ContainerProps) => {
  // Add all classs to an array
  const addAllClasses = ["container"]
  // className prop checking
  if (className) {
    addAllClasses.push(className)
  }

  return (
    <ContainerWrapper
      className={addAllClasses.join("  ")}
      fullWidth={fullWidth}
    >
      {children}
    </ContainerWrapper>
  )
}

export default Container
